import {useRef, useState, useEffect} from 'react'

function useSmartPopup() {
  const [offset, setOffset] = useState(0)
  const popup = useRef(null)

  useEffect(() => {
    function updateOffset() {
      const popupHeight = popup.current.clientHeight
      const windowHeight = window.innerHeight
      const offset = windowHeight > popupHeight
        ? (windowHeight - popupHeight) / 2
        : 0
      setOffset(offset)
    }

    window.addEventListener('resize', updateOffset)
    updateOffset()

    return () => {
      window.removeEventListener('resize', updateOffset)
    }
  }, [])

  return {popup, offset}
}

export default useSmartPopup
