import React from 'react'
import './Row.css'
import mixin from 'utils/mixin'

const mix = mixin('Row')

const Row = ({type, children, className}) => (
  <div className={mix(type, className)}>
    {children}
  </div>
)

export default Row
