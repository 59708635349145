import React from 'react'
import {useDispatch} from 'react-redux'
import {closePopup} from './popupsSlice'
import Button from 'components/Button'
import useSmartPopup from 'utils/smartPopup'
import mixin from 'utils/mixin'
import './Notification.css'

const mix = mixin('Notification')

const Notification = ({id, message, className}) => {
  const {popup, offset} = useSmartPopup()
  const dispatch = useDispatch()
  return (
    <div ref={popup} className={mix(message.type, className)} style={{top: offset}}>
      <h2 className="Notification-title">
        {message.title}
      </h2>
      <p className="Notification-description">
        {message.description}
      </p>
      <Button
        className="Notification-ok"
        onClick={() => {
          dispatch(closePopup(id))
          if (message.type === 'success') {
            dispatch(closePopup(message.parent))
          }
        }}
      >
        Close
      </Button>
    </div>
  )
}

export default Notification
