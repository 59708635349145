import React from 'react'
import './Advantage.css'
import Icon from 'components/Icon'
import List from 'components/List'
import mixin from 'utils/mixin'

const mix = mixin('Advantage')

const Advantage = ({item, className}) => (
  <article className={mix(null, className)}>
    <h3 className="Advantage-title">
      {item.title}
    </h3>
    <Icon name={item.icon} className="Advantage-icon"/>
    <p className={
      item.isLong
        ? 'Advantage-description Advantage-description--long'
        : 'Advantage-description'
    }>
      {item.description + ': '}
    </p>
    {item.isLong ? <ul className="Advantage-facts">
      {item.facts.map((fact, index) => (
        <li key={index} className="Advantage-fact">
          {fact}
        </li>
      ))}
    </ul> : <List type={['dots', 'small']} items={item.facts}/>}
  </article>
)

export default Advantage
