import React, {useRef, useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {openPopup} from 'popups/popupsSlice'
import produce from 'immer'
import Button from 'components/Button'
import Icon from 'components/Icon'
import './Showcase.css'
import items from 'data/showcase'
import useSwipe from 'utils/swipe'

const Showcase = () => {
  const [offset, setOffset] = useState(0)
  const status = useLazyLoad(offset)
  const carousel = useRef(null)
  const item = items[offset]
  const dispatch = useDispatch()

  const touch = useSwipe(action => {
    if (action === 'left') {
      handleLeft()
    } else {
      handleRight()
    }
  })

  function handleLeft(event) {
    setOffset(offset => offset > 0 ? offset - 1 : 0)
  }

  function handleRight(event) {
    const latest = items.length - 1
    setOffset(offset => offset < latest ? offset + 1 : latest)
  }

  function handleClick(event) {
    carousel.current.focus()
  }

  function handleKeyDown(event) {
    if (event.key === 'ArrowLeft') {
      handleLeft(event)
    } else if (event.key === 'ArrowRight') {
      handleRight(event)
    }
  }

  return (
    <section
      className="Showcase"
      ref={carousel}
      tabIndex="-1"
      onClick={handleClick}
      onMouseEnter={handleClick}
      onKeyDown={handleKeyDown}
      onTouchStart={touch.handleStart}
      onTouchMove={touch.handleMove}
      onTouchEnd={touch.handleEnd}
      onMouseDown={touch.handleStart}
      onMouseMove={touch.handleMove}
      onMouseUp={touch.handleEnd}
    >
      <div className="Showcase-info">
        <div className="Showcase-header">
          <span className="Showcase-small">
          Scroll right
          </span>
          <h2 className="Showcase-city">
            {item.city}
          </h2>
          <Button
            type="round"
            className="Showcase-cta"
            onClick={() => dispatch(openPopup('form', 'showcase'))}
          >
            I want the same
          </Button>
        </div>
        <div className="Showcase-numbers">
          <div className="Showcase-info-item">
            <h5 className="Showcase-title">
            park area
            </h5>
            <div className="Showcase-info-line">
              <Icon name="area" className="Showcase-icon"/>
              <span className="Showcase-number">{item.area}</span>
              <span className="Showcase-units">sq.m.</span>
            </div>
          </div>
          <div className="Showcase-info-item">
            <h5 className="Showcase-title">
            capacity
            </h5>
            <div className="Showcase-info-line">
              <Icon name="team" className="Showcase-icon"/>
              <span className="Showcase-number">{item.capacity}</span>
              <span className="Showcase-units">persons</span>
            </div>
          </div>
          <div className="Showcase-info-item">
            <h5 className="Showcase-title">
            throughput
            </h5>
            <div className="Showcase-info-line">
              <Icon name="population" className="Showcase-icon"/>
              <span className="Showcase-number">{item.flow}</span>
              <span className="Showcase-units">person/hour</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="Showcase-items"
        style={{
          width: `${100 * items.length}%`,
          transform: `translateX(-${offset * 100 / items.length}%) translateX(${touch.offset}px)`,
          transition: touch.offset === 0
            ? 'transform ease-out 0.4s'
            : 'transform ease-out 0.2s'
        }}
      >
        {items.map((item, index) => {
          const loaded = isLoaded(status, index, 'bg')
          return (
            <div
              className={
                loaded
                  ? 'Showcase-item Showcase-item--loaded'
                  : 'Showcase-item'
              }
              key={index}
              style={{
                width: `${100 / items.length}%`,
                backgroundImage: loaded ? `url(${item.background})` : null
              }}
            />
          )
        })}
      </div>
      <div className="Showcase-thumbs">
        <div className="Showcase-thumb">
          <div
            className={
              isLoaded(status, offset, 'i1')
                ? 'Showcase-thumb-img Showcase-thumb-img--loaded'
                : 'Showcase-thumb-img'
            }
            style={{
              backgroundImage: isLoaded(status, offset, 'i1')
              ? `url(${items[offset].images[0]})`
              : null
            }}
          />
        </div>
        <div className="Showcase-thumb">
          <div
            className={
              isLoaded(status, offset, 'i2')
                ? 'Showcase-thumb-img Showcase-thumb-img--loaded'
                : 'Showcase-thumb-img'
            }
            style={{
              backgroundImage: isLoaded(status, offset, 'i2')
              ? `url(${items[offset].images[1]})`
              : null
            }}
          />
        </div>
        <div className="Showcase-thumb">
          <div
            className={
              isLoaded(status, offset, 'i3')
                ? 'Showcase-thumb-img Showcase-thumb-img--loaded'
                : 'Showcase-thumb-img'
            }
            style={{
              backgroundImage: isLoaded(status, offset, 'i3')
              ? `url(${items[offset].images[2]})`
              : null
            }}
          />
        </div>
      </div>
      <div className="Showcase-dots">
        {items.map((item, index) => (
          <div
            key={index}
            className={
              index === offset
                ? 'Showcase-dot Showcase-dot--active'
                : 'Showcase-dot'
            }
            onClick={() => setOffset(index)}
          />
        ))}
      </div>
      <div className="Showcase-left" onClick={handleLeft}/>
      <div className="Showcase-right" onClick={handleRight}/>
    </section>
  )
}

function isLoaded(status, item, img) {
  return status[item] && status[item][img]
}

function useLazyLoad(pos) {
  const [status, setStatus] = useState({})
  const tokens = useRef({})

  useEffect(() => {
    function setStatusSafe(pos, img) {
      setStatus(produce(status => {
        if (!status[pos]) {
          status[pos] = {}
        }
        status[pos][img] = true
      }))
    }

    if (!tokens.current[pos]) {
      const newTokens = [{}, {}, {}, {}]
      tokens.current[pos] = newTokens
      loadImage(items[pos].background, newTokens[0])
        .then(() => setStatusSafe(pos, 'bg'))
        .catch(e => console.log(e))
      loadImage(items[pos].images[0], newTokens[1])
        .then(() => setStatusSafe(pos, 'i1'))
        .catch(e => console.log(e))
      loadImage(items[pos].images[1], newTokens[2])
        .then(() => setStatusSafe(pos, 'i2'))
        .catch(e => console.log(e))
      loadImage(items[pos].images[2], newTokens[3])
        .then(() => setStatusSafe(pos, 'i3'))
        .catch(e => console.log(e))
    }
  }, [pos])

  return status
}

function loadImage(src, token) {
  return new Promise((resolve, reject) => {
    const handleLoad = () => resolve()
    const img = new Image()
    img.addEventListener('load', handleLoad)
    token.cancel = () => {
      img.removeEventListener('load', handleLoad)
      reject('Загрузка отменена')
    }
    img.src = src
  })
}

export default Showcase
