export default [
  {
    authors: 'Julia and Sergey Sokolov',
    text: 'Once my wife and I were walking in the park and saw a rope course in the trees. There was a desire to do something similar in my city. On the recommendation of the owner of the rope town, we turned to "Outride Park". After some coordination on the territory, the park was built. Now we are working. The park paid off in 4 months fully worked.',
    photo: require('./1.jpg')
  },
  {
    authors: 'Elena Zhban',
    text: 'I was looking for information on rope parks, I came across the website of the company "Outride Park". They called a specialist who organized all the work on the construction. After 4 weeks, the rope park was launched. They work quickly and smoothly, I recommend!',
    photo: require('./2.jpg')
  },
  {
    authors: 'Mikhail Gavrilov',
    text: 'Previously applied for services to another company for rope parks. A year later, I decided to expand. I invited specialists from "Outride Park", I was pleasantly surprised, as a result, the quality of installation and manufacturing turned out to be much higher, and the cost of services was lower. I recommend!',
    photo: require('./3.png'),
    square: true
  },
  {
    authors: 'Emily and Mike',
    text: 'We have been thinking about opening a rope town for a long time, we called many companies and received many offers. The offer of the company "Outride Park" suited us more than others. Good luck in job!',
    photo: require('./4.jpg')
  }
]
