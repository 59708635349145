function mixClassName(base) {
  return (mods, mixin) => withMixin(withMods(base, mods), mixin)
}

function withMods(base, mods) {
  if (Array.isArray(mods)) {
    const joined = mods.map((mod) => `${base}--${mod}`).join(' ')
    return `${base} ${joined}`
  }
  if (typeof mods === 'string') {
    return `${base} ${base}--${mods}`
  }
  return base
}

function withMixin(base, mixin) {
  if (typeof mixin === 'string') {
    return `${base} ${mixin}`
  }
  return base
}

export default mixClassName
