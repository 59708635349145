import React, { useEffect, useState, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import { useDispatch } from 'react-redux'
import { openPopup } from './popupsSlice'
import Button from 'components/Button'
import mixin from 'utils/mixin'
import useSmartPopup from 'utils/smartPopup'
import './ParkPopup.css'

const mix = mixin('ParkPopup')

const ParkPopup = ({ park, className }) => {
  const [current, isLoaded, showPreview] = useGallery(park.images)
  const { popup, offset } = useSmartPopup()
  const dispatch = useDispatch()

  return (
    <div ref={popup} className={mix(null, className)} style={{ top: offset }}>
      <div className="ParkPopup-gallery">
        <div className="ParkPopup-preview">
          <a
            href={park.images[current].original}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={isLoaded ? park.images[current].preview : ''}
              alt=""
              className="ParkPopup-preview-img"
            />
          </a>
        </div>
        <div className="ParkPopup-thumbs">
          {park.images.map((item, index) => (
            <a
              key={index}
              href={item.original}
              className="ParkPopup-thumb"
              onClick={e => {
                e.preventDefault()
                showPreview(index)
              }}
            >
              <img
                src={item.thumbnail}
                alt=""
                className="ParkPopup-thumb-img"
              />
            </a>
          ))}
        </div>
      </div>
      <div className="ParkPopup-info">
        <h2 className="ParkPopup-title">{park.title}</h2>
        {park.price && (
          <div className="ParkPopup-prices">
            <span className="ParkPopup-price">
              {park.price.toLocaleString() + ' руб. '}
            </span>
            {park.oldPrice && (
              <span className="ParkPopup-price ParkPopup-price--old">
                {park.oldPrice.toLocaleString() + ' руб.'}
              </span>
            )}
          </div>
        )}
        <div className="ParkPopup-actions">
          <Button onClick={() => dispatch(openPopup('form', park.title))}>
          Buy
          </Button>
        </div>
        <div className="ParkPopup-description">
          <ReactMarkdown children={park.description} />
        </div>
      </div>
    </div>
  )
}

function useGallery(items) {
  const [isLoaded, setIsLoaded] = useState({})
  const [current, setCurrent] = useState(0)
  const tokens = useRef({})

  function showPreview(index) {
    if (!isLoaded[index]) {
      tokens.current[index] = {}
      loadImage(items[index].preview, tokens.current[index])
        .then(() => {
          setIsLoaded(state => ({ ...state, [index]: true }))
          tokens.current[index] = undefined
        })
        .catch(e => console.log(e))
    }
    setCurrent(index)
  }

  function cancelLoading() {
    Object.keys(tokens.current).forEach(index => {
      if (tokens.current[index]) {
        tokens.current[index].cancel()
      }
    })
  }

  useEffect(() => {
    tokens.current[0] = {}
    loadImage(items[0].preview, tokens.current[0])
      .then(() => {
        setIsLoaded(state => ({ ...state, 0: true }))
        tokens.current[0] = undefined
      })
      .catch(e => console.log(e))
    return () => {
      cancelLoading()
    }
  }, [items])

  return [current, !!isLoaded[current], showPreview]
}

function loadImage(src, token) {
  return new Promise((resolve, reject) => {
    const done = () => resolve()
    const img = new Image()
    img.addEventListener('load', done)
    token.cancel = () => {
      img.removeEventListener('load', done)
      reject('Загрузка отменена')
    }
    img.src = src
  })
}

export default ParkPopup
