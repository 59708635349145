import React from 'react'
import Screen from 'components/Screen'
import Heading from 'components/Heading'
import Icon from 'components/Icon'
import CalcForm from './CalcForm'
import background from './background.jpg'
import './Calculator.css'

const Calculator = () => (
  <Screen className="Calculator" background={background}>
    <Heading level="2">
      <span className="small">
      income calculator
      <Icon name="calculator" className="small-icon"/>
      </span>
      Rope park as a business
    </Heading>
    <div className="sub-cta">
      <strong>Calculate the revenue </strong>for 12 months of park operation
    </div>
    <CalcForm/>
  </Screen>
)

export default Calculator
