import React from 'react'
import Icon from 'components/Icon'
import './Contacts.css'
import mixin from 'utils/mixin'

const mix = mixin('Contacts')

const Contacts = ({items, type, className}) => (
  <div className={mix(type, className)}>
    <a href="/" className="Contacts-logo">
      Outride Park
    </a>
    <ul className="Contacts-items">
      {items.map(({type, value, url}, index) => (
        <li key={index} className="Contacts-item">
          <a href={url} className="Contacts-link">
            <Icon name={type} className="Contacts-icon"/>
            <span>{value}</span>
          </a>
        </li>
      ))}
    </ul>
  </div>
)

export default Contacts
