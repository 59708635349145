import React, {useRef, useState, useEffect} from 'react'
import Screen from 'components/Screen'
import Heading from 'components/Heading'
import ParkInfo from './ParkInfo'
import Park from './Park'
import './Parks.css'

const Parks = ({items, title, advantage}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const container = useRef(null)
  const timer = useRef(null)

  useEffect(() => {
    function handleScroll(event) {
      const {parentNode} = container.current.parentNode
      const {scrollY} = window
      const {offsetTop} = parentNode
      if (scrollY > offsetTop - 2000) {
        timer.current = setTimeout(() => {
          window.removeEventListener('scroll', handleScroll)
          setIsLoaded(true)
        }, 50)
      }
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearTimeout(timer.current)
    }
  }, [container])

  return (
    <Screen className="Parks">
      <Heading level="2">
        {title}
      </Heading>
      <ParkInfo advantage={advantage}/>
      <div ref={container} className="Parks-items">
        {items.map((id) => (
          <Park
            key={id}
            id={id}
            className="Parks-item"
            isLoaded={isLoaded}
          />
        ))}
      </div>
    </Screen>
  )
}

export default Parks
