import React, {useRef} from 'react'
import Icon from 'components/Icon'
import './Figure.css'
import mixin from 'utils/mixin'
import useLazyBackground from 'utils/lazyBackground'

const mix = mixin('Figure')

const Figure = ({src, alt, icon, type, className}) => {
  const container = useRef(null)
  const imgSrc = useLazyBackground(container, src)
  return (
    <div ref={container} className={mix(type, className)}>
      <img src={imgSrc} alt={alt} className="Figure-img"/>
      {icon && <div className="Figure-circle">
        <Icon name={icon} className="Figure-icon"/>
      </div>}
    </div>
  )
}

export default Figure
