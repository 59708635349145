import React from 'react'
import {useDispatch} from 'react-redux'
import {openPopup} from 'popups/popupsSlice'
import Screen from 'components/Screen'
import Heading from 'components/Heading'
import {Row, Cell} from 'components/Grid'
import Button from 'components/Button'
import Figure from 'components/Figure'
import Text from 'components/Text'
import background from './matterhorn.jpg'
import splash from './splash.png'

const Offer2 = () => {
  const dispatch = useDispatch()
  return (
    <Screen type="wide" className="Offer2" background={background}>
      <Row>
        <Cell size="6">
          <Figure type="wide" src={splash} alt=""/>
        </Cell>
        <Cell size="6" className="text-2">
          <Heading type="big" level="2">
          Get
             <br/>
             offer<strong className="hot">now</strong>
          </Heading>
          <Text type="big">
          A personal manager will help you choose
          rope park for your budget.
          </Text>
          <Button onClick={() => dispatch(openPopup('form', 'offer-2'))}>
          Order a project calculation for my budget
          </Button>
        </Cell>
      </Row>
    </Screen>
  )
}

export default Offer2
