import React from 'react'
import './Text.css'
import mixin from 'utils/mixin'

const mix = mixin('Text')

const Text = ({type, children}) => (
  <p className={mix(type)}>
    {children}
  </p>
)

export default Text
