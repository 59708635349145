import React from 'react'
import './Advantages.css'
import Advantage from './Advantage'
import Icon from 'components/Icon'
import Screen from 'components/Screen'
import Heading from 'components/Heading'
import advantages from 'data/advantages'
import background from './background.jpg'

const Advantages = () => (
  <Screen type="collapsed" className="Advantages" background={background}>
    <Heading type={['big', 'full']} level="2">
      <span className="small">
      Advantages <Icon name="rope" className="small-icon"/>
      </span>
      Advantages
    </Heading>
    <div className="Advantages-items">
      {advantages.map((item, index) => (
        <Advantage key={index} item={item} className="Advantages-item"/>
      ))}
    </div>
  </Screen>
)

export default Advantages
