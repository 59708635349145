import React from 'react'
import Icon from 'components/Icon'
import './Chat.css'

const Chat = ({href, children}) => (
  <a href={href} className="Chat">
    <Icon name="whatsapp" className="Chat-icon"/>
    <span className="Chat-cta">
      {children}
    </span>
  </a>
)

export default Chat
