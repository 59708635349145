import React from 'react'
import './ParkInfo.css'
function ParkInfo({advantage}) {
  if (advantage !=="New attraction!")
  return(
  <ul className="ParkInfo">
    <li className="ParkInfo-item">
      Can have up to 3 tiers
    </li>
    <li className="ParkInfo-item ParkInfo-item--routes">
      <span className="ParkInfo-item-text">
       May include routes:
      </span>
      <ul className="ParkInfo-routes">
        <li className="ParkInfo-route ParkInfo-route--orange">
        Children's
        </li>
        <li className="ParkInfo-route ParkInfo-route--green">
        Teenager
        </li>
        <li className="ParkInfo-route ParkInfo-route--blue">
        Family
        </li>
        <li className="ParkInfo-route ParkInfo-route--red">
        Extreme
        </li>
      </ul>
    </li>
    <li className="ParkInfo-item">
    It can be located at a height of <strong>0.5 m to 10 m.</strong>
    </li>
    <li className="ParkInfo-item">
      {advantage}
    </li>
  </ul>
)
else return (
  <ul className="ParkInfo">
    <li className="ParkInfo-item ParkInfo-item--routes">
      <span className="ParkInfo-item-text">
      Various route options. Good for:
      </span>
      <ul className="ParkInfo-routes">
        <li className="ParkInfo-route ParkInfo-route--orange">
        Children
        </li>
        <li className="ParkInfo-route ParkInfo-route--green">
        Teenagers
        </li>
        <li className="ParkInfo-route ParkInfo-route--blue">
        Families
        </li>
        <li className="ParkInfo-route ParkInfo-route--red">
        Extreme
        </li>
      </ul>
    </li>
      Can be positioned at different heights
    <li className="ParkInfo-item">
    {advantage}
    </li>
  </ul>
)}

export default ParkInfo
