import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {openPopup} from './popupsSlice'
import './PopupForm.css'
import useSmartPopup from 'utils/smartPopup'
import mixin from 'utils/mixin'
import sendOrder from './sendOrder'

const mix = mixin('PopupForm')

const PopupForm = ({id, source, className}) => {
  const {popup, offset} = useSmartPopup()
  const dispatch = useDispatch()
  const [order, setOrder] = useState({
    name: '',
    tel: '',
    email: ''
  })

  function handleChange(event) {
    const {name, value} = event.target
    setOrder(order => ({
      ...order,
      [name]: value
    }))
  }

  function handleSubmit(event) {
    event.preventDefault()
    sendOrder({...order, source})
      .then(() => {
        dispatch(openPopup('notification', {
          type: 'success',
          parent: id,
          title: 'Your application has been sent!!!',
          description: 'Our manager will contact you as soon as possible'
        }))
      })
  }

  return (
    <form ref={popup} className={mix(null, className)} onSubmit={handleSubmit} style={{top: offset}}>
      <h2 className="PopupForm-title">
      Send us your contacts
      </h2>
      <p className="PopupForm-description">
      And we will contact you during the day
      </p>
      <input
        name="name"
        type="text"
        className="PopupForm-input"
        placeholder="Name"
        onChange={handleChange}
      />
      <input
        name="tel"
        type="tel"
        className="PopupForm-input"
        placeholder="Phone Number"
        onChange={handleChange}
        required
      />
      <input
        name="email"
        type="email"
        className="PopupForm-input"
        placeholder="Your Email"
        onChange={handleChange}
      />
      <div className="PopupForm-agreement">
        <input
          id="agreement"
          name="agreement"
          type="checkbox"
          value="ok"
          className="PopupForm-checkbox"
          required
        />
        <label htmlFor="agreement" className="PopupForm-checkbox-label">
        I confirm my consent to the processing of my personal data in accordance with&#160;
          <a href="/policy" className="PopupForm-checkbox-policy">
          privacy policy
          </a>
        </label>
      </div>
      <button type="submit" className="PopupForm-submit">
        Send.
      </button>
    </form>
  )
}

export default PopupForm
