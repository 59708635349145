import React from 'react'
import './List.css'
import mixin from 'utils/mixin'

const mix = mixin('List')

const List = ({type, items, className}) => (
  <ul className={mix(type, className)}>
    {items.map((item, index) => (
      <li key={index} className="List-item">
        {Array.isArray(item) ? item.map((text, index) => (
          index === 0
            ? <span key={index}>{text}</span>
            : <div key={index}>{text}</div>
        )) : item}
      </li>
    ))}
  </ul>
)

export default List
