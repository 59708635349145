import React from 'react'
import Screen from 'components/Screen'
import {Row, Cell} from 'components/Grid'
import Figure from 'components/Figure'
import Heading from 'components/Heading'
import Text from 'components/Text'
import List from 'components/List'
import Info from './Info'
import background from './background.jpg'
import figure from './figure.jpg'
import './Solution.css'

const Solution = () => (
  <Screen type="collapsed" className="Solution" background={background}>
    <Row>
      <Cell size="6">
        <Figure src={figure} alt="Park example"/>
      </Cell>
      <Cell size="6" className="text">
        <Heading level="2" className="topstick">
          <em>
          Rope park  <br/>
          </em>
          is a great solution for landscaping
        </Heading>
        <Text>
        Creating space for outdoor activities and landscaping is a steadily growing trend in every municipality.
        </Text>
        <List items={[
          'Development of a turnkey design project',
          'Manufacturing and installation Launching the',
          'facility into operation'
        ]}/>
        <Text>
          <strong>We carry out a full production cycle </strong> производства <br/>
          Rope park.
        </Text>
      </Cell>
    </Row>
    <Heading level="4" className="topstick">
    We work on:
    </Heading>
    <Row>
      <Cell size="6">
        <Info items={[
          {icon: 'ruble', title: ['prepaid and ', ' postpaid system']},
          {icon: 'calendar', title: ['calendar plan']},
          {icon: 'support', title: ['Post-warranty', ' customer service']}
        ]}/>
      </Cell>
      <Cell size="6" className="text">
        <List type="dots" items={[
          'Idea',
          'Production on time',
          'Quality installation'
        ]}/>
      </Cell>
    </Row>
  </Screen>
)

export default Solution
