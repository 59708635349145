import React from 'react'
import Screen from 'components/Screen'
import Heading from 'components/Heading'
import Figure from 'components/Figure'
import {Row, Cell} from 'components/Grid'
import Text from 'components/Text'
import List from 'components/List'
import certificates from './certificates.jpg'
import background from './background.jpg'
import worker from './worker.jpg'
import park2 from './park-2.jpg'
import park from './park.jpg'
import ship from './ship.jpg'
import './Production.css'

const Production = () => (
  <Screen type="collapsed" className="Production" background={background}>
    <Row className="block">
      <Cell size="6">
        <Figure src={worker} alt="Парк &#34;Преодоление&#34;"/>
      </Cell>
      <Cell size="6" className="text">
        <Heading level="2" className="topstick">
        Plant-manufacturer <br/>of rope parks
        </Heading>
        <List items={[
          '1000m2 assembly and metalwork shop, 700m2 woodworking shop allow us to process large orders',
          ['Successful experience of working with large companies']
        ]}/>
      </Cell>
    </Row>
    <Row type="reversed" className="block">
      <Cell size="6">
        <Figure
          src={ship}
          alt="Парк-корабль"
          type="minor"
          icon="key"
        />
      </Cell>
      <Cell size="6">
        <Heading level="3">
          We work on a turnkey basis
        </Heading>
        <Text>
          We carry out a full cycle:
        </Text>
        <List items={[
          'Design (we will select and implement an idea in a 3D sketch if you don’t like the typical model)',
          'Design (Architectural solution with reference to the area)',
          'Production (quality control at all stages of production, using proven materials)',
          'Installation (on-site team with tools or installation supervision, special equipment is called on site at the time of need)'
        ]}/>
      </Cell>
    </Row>
    <Row type="reversed" className="block">
      <Cell size="6">
        <Figure
          src={park}
          alt="Большой высокий парк"
          type="minor"
          icon="stamp"
        />
      </Cell>
      <Cell size="6">
        <Heading level="3">
          Result Guaranteed
        </Heading>
        <Text>
          At the legal level, we certify the terms and give a guarantee for the high-quality fulfillment of our obligations. In fact, we strive to pleasantly surprise the customer and make an exchange with an excess in order to leave only a positive impression. For the entire period of operation of our fleets (&lt;
          10 years), not a single unit of the working or safety system has collapsed.
        </Text>
      </Cell>
    </Row>
    <Row className="block">
      <Cell size="6">
        <Figure
          src={park2}
          alt="Парк сверху"
          type="minor"
          icon="staff"
        />
      </Cell>
      <Cell size="6" className="text">
        <Heading level="3">
          Post-Launch Service
        </Heading>
        <Text>
          Throughout the year, our specialists will support your fleet at all stages of operation and seek to improve the competence of your instructors. Our partners will train and issue certificates on the spot.
        </Text>
      </Cell>
    </Row>
  </Screen>
)

export default Production
