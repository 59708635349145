import React from 'react'
import {useDispatch} from 'react-redux'
import {openPopup} from 'popups/popupsSlice'
import Screen from 'components/Screen'
import Contacts from 'components/Contacts'
import Heading from 'components/Heading'
import Button from 'components/Button'
import Text from 'components/Text'
import Chat from './Chat'
import './Offer.css'

const Offer = () => {
  const dispatch = useDispatch()
  return (
    <Screen className="Offer" parallax>
      <Contacts
        items={[
          {type: 'email', value: 'outridepark@gmail.ru', url: 'mailto:outridepark@gmail.com'},
          {type: 'tel', value: '+7 (996)-639-87-48', url: 'tel:89966398748'},
          {type: 'tel', value: '+7 (495)-155-74-51', url: 'tel:84951557451'},
        ]}
      />
      <Heading level="1">
        <span className="small">
          Turnkey and guaranteed
        </span>
        Rope park<br/>
        production

      </Heading>
      <Text>
      Design, manufacture and installation according to standards.
      </Text>
      <div>
        <Button onClick={() => dispatch(openPopup('form', 'offer'))}>
        Get a park quote
        </Button>
        <Chat href="https://wa.me/79966398748">
        write a message on whatsapp

        </Chat>
      </div>
    </Screen>
  )
}

export default Offer
