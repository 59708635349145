export default [
  {
    title: 'Durability',
    icon: 'premium',
    description: 'Materials used:',
    facts: [
      'Galvanized cables and fasteners (do not rust)',
      'Synthetic ropes (do not rot)',
      'Dry treated wood (does not crack)'
    ]
  },
  {
    title: 'Reliability',
    icon: 'ok',
    description: 'Security Guaranteed:',
    facts: [
      'Long-term operation of the insurance systems and the working system',
      'Passports and certificates of quality of materials, test reports of units',
      'Experience in the installation and operation of rope parks'
    ]
  },
  {
    title: 'Design',
    icon: 'sketch',
    description: 'In appearance, the rope park can have different styles:',
    facts: [
      'Antique',
      'Robin Hood',
      'Pirate Ship',
      'Ancient China',
      'Minions',
      'Transformers',
      'Spiderman',
      'Superheroes'],
    isLong: false
  }
]
