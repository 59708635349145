import React from 'react'
import {useDispatch} from 'react-redux'
import {openPopup} from 'popups/popupsSlice'
import Contacts from 'components/Contacts'
import Icon from 'components/Icon'
import Button from 'components/Button'
import './Footer.css'

const socials = [
  {
    href: 'https://ok.ru/kanatpark',
    icon: 'soc-ok'
  },
  {
    href: 'https://vk.com/outridepark',
    icon: 'soc-vk'
  },
  {
    href: 'https://facebook.com/outridepark',
    icon: 'soc-fb'
  },
  {
    href: 'https://pinterest.ru/outridepark/',
    icon: 'soc-pin'
  },
  {
    href: 'https://youtube.com/channel/UC5JbMK_qz5xtYAZogmvtQ3A?view_as=subscriber',
    icon: 'soc-yt'
  }
]

const Footer = () => {
  const dispatch = useDispatch()
  return (
    <footer className="Footer">
      <div className="Footer-inner">
        <div className="Footer-main">
          <Contacts
            className="Footer-contacts"
            type="small"
            items={[
              {type: 'tel', value: '+7 (996) 639-87-48', url: 'tel:89966398748'},
              {type: 'tel', value: '+7 (495) 155-74-51', url: 'tel:84951557451'},
              {type: 'email', value: 'outridepark@gmail.com', url: 'outridepark@gmail.com'}
            ]}
          />
          <div className="Footer-socials">
            {socials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                target="_blank"
                className="Footer-social-link"
                rel="noopener noreferrer"
              >
                <Icon name={item.icon} className="Footer-social-icon"/>
              </a>
            ))}
          </div>
        </div>
        <form className="Footer-form">
          <Button
            type="round"
            className="Footer-submit"
            onClick={() => dispatch(openPopup('form', 'footer'))}
          >
            call back
          </Button>
        </form>
      </div>
      <div className="Footer-small">
        <span className="Footer-policy">
        Privacy Policy
        </span>
        <div className="Footer-copy">
        All rights reserved (с) 2010-2023
        </div>
      </div>
    </footer>
  )
}

export default Footer
