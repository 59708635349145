import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { openPopup } from 'popups/popupsSlice'
import './Park.css'
import mixin from 'utils/mixin'

const mix = mixin('Park')

const Park = ({ id, className, isLoaded }) => {
  const [item, setItem] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    import('data/parks').then(parks => {
      setItem(parks.allParks[id])
    })
  }, [id])

  return (
    <article className={mix(!item ? 'loading' : '', className)}>
      <h3 className="Park-title">{item && item.title}</h3>
      <div
        className="Park-figure"
        onClick={e => {
          dispatch(openPopup('park', item))
        }}
      >
        <img
          src={item && isLoaded ? item.images[0].card : ''}
          alt=""
          className="Park-img Park-img--main"
        />
        <img
          src={item && isLoaded ? item.images[1].card : ''}
          alt=""
          className="Park-img Park-img--hover"
        />
      </div>
      <p className="Park-description">{item && item.shortDescription}</p>
      {item && item.price && (
        <div className="Park-prices">
          <span className="Park-price">
            {item.price.toLocaleString() + ' руб. '}
          </span>
          {item.oldPrice && (
            <span className="Park-price Park-price--old">
              {item.oldPrice.toLocaleString() + ' руб.'}
            </span>
          )}
        </div>
      )}
    </article>
  )
}

export default Park
