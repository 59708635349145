import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {openPopup} from 'popups/popupsSlice'
import Button from 'components/Button'
import './CalcForm.css'

const TICKET_PRICE = 7 // (рублей/билет)

const CalcForm = () => {
  const [value, setValue] = useState(240)
  const monthRevenue = value * 20 * TICKET_PRICE
  const yearRevenue = monthRevenue * 12
  const dispatch = useDispatch()

  function handleChange(event) {
    setValue(parseInt(event.target.value))
  }

  return (
    <form className="CalcForm">
      <div className="CalcForm-result">
        <div className="CalcForm-total">
          <div className="CalcForm-term">
           Revenue per month
          </div>
          <div className="CalcForm-value">
            {monthRevenue.toLocaleString() + ' $'}
          </div>
        </div>
        <div className="CalcForm-total CalcForm-total--small">
          <div className="CalcForm-term">
            Revenue for the year
          </div>
          <div className="CalcForm-value">
            {yearRevenue.toLocaleString() + ' $'}
          </div>
        </div>
      </div>
      <div className="CalcForm-inputs">
        <label htmlFor="stream" className="CalcForm-label">
        Average traffic (person/day)
          <span className="CalcForm-quantity">
            {value}
          </span>
        </label>
        <input
          id="stream"
          name="stream"
          type="range"
          min="80"
          max="1200"
          step="20"
          value={value}
          className="CalcForm-range"
          onChange={handleChange}
        />
        <ul className="CalcForm-captions">
          <li
            className="CalcForm-caption"
            style={{opacity: nearCoof(200, value, 'left')}}
          >
            Low permeability
          </li>
          <li
            className="CalcForm-caption"
            style={{opacity: nearCoof(500, value)}}
          >
            Middle
          </li>
          <li
            className="CalcForm-caption"
            style={{opacity: nearCoof(800, value)}}
          >
            High
          </li>
          <li
            className="CalcForm-caption"
            style={{opacity: nearCoof(960, value, 'right')}}
          >
            Very high
          </li>
        </ul>
        <Button
          type="round"
          className="CalcForm-order"
          onClick={() => dispatch(openPopup('form', 'calculator'))}
        >
          Calculate exact profit
        </Button>
      </div>
    </form>
  )
}

function nearCoof(target, value, edge = false) {
  const edgeDist = edge === 'left'
    ? value < target ? 0 : 1
    : edge === 'right'
      ? value > target ? 0 : 1
      : 1
  const dist = Math.min(Math.abs(target - value) / 200, 1, edgeDist)
  return 0.5 + (1 - dist * dist * dist) / 2
}

export default CalcForm
