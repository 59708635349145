import React from 'react'
import Offer from 'screens/Offer'
import Solution from 'screens/Solution'
import Production from 'screens/Production'
import Calculator from 'screens/Calculator'
import Showcase from 'screens/Showcase'
import Parks from 'screens/Parks'
import Offer2 from 'screens/Offer2'
import Advantages from 'screens/Advantages'
import Steps from 'screens/Steps'
import Testimonials from 'screens/Testimonials'
import Objects from 'screens/Objects'
import Footer from 'screens/Footer'
import Popups from 'popups'
import parks from 'data/parks.json'

const App = () => (
  <>
    <Offer />
    <Solution />
    <Production />
    <Calculator />
    <Showcase />
    <Parks
      items={parks.unitParks}
      title="Rope park modular"
      advantage="Can take up minimal space"
    />
    <Parks
      items={parks.pillarParks}
      title="Rope parquet on supports"
      advantage="Have cross stages"
    />
    <Parks
      items={parks.treeParks}
      title="Rope park in the trees"
      advantage="Have a total length of more than 1 km. and trolls up to 100 meters"
    />
    <Parks
      items={parks.newparks}
      title="Zip coaster"
      advantage="New attraction!"
    />
    <Offer2 />
    <Advantages />
    <Steps />
    <Testimonials />
    <Objects />
    <Footer />
    <Popups />
  </>
)

export default App
