import React from 'react'
import Icon from 'components/Icon'
import './Info.css'
import mixin from 'utils/mixin'

const mix = mixin('Info')

const Info = ({items, className}) => (
  <ul className={mix(null, className)}>
    {items.map((item, index) => (
      <li key={index} className="Info-item">
        <Icon name={item.icon} className="Info-icon"/>
        <div className="Info-title">
          {Array.isArray(item.title) ? item.title.map((line, index) => (
            <div key={index} className="Info-line">
              {line}
            </div>
          )) : item.title}
        </div>
      </li>
    ))}
  </ul>
)

export default Info
