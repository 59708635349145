export default [
  {
    title: 'Call to the company',
    image: require('./1.jpg'),
    description: [
      'Call our company ',
      'or leave a request on the site.'
    ]
  },
  {
    title: 'Commercial offer',
    image: require('./2.jpg'),
    description: [
      'We will prepare a personal ',
      'offer after discussing ',
      'all the details with you.'
    ]
  },
  {
    title: 'Treaty',
    image: require('./3.jpg'),
    description: [
      'After you have agreed on ',
      'cost, you signed the contract.'
    ]
  },
  {
    title: 'Manufacturing',
    image: require('./4.jpg'),
    description: [
      'After receiving the advance payment, we ',
      'start making rope ',
      'park to specification.'
    ]
  },
  {
    title: 'Sending the park to the customer',
    image: require('./5.jpg'),
    description: [
      'After manufacturing, we send your park to you ',
      'by a transport company.'
    ]
  },
  {
    title: 'Assistance in the installation',
    image: require('./5f.jpg'),
    description: [
      'Our best specialists come to the installation site ',
      'and help install and set up the park.',
    ]
  },
  {
    title: 'Park launch',
    image: require('./6.jpg'),
    description: [
      'The park begins to work ',
      'and bring you profit',
    ]
  },
  {
    title: 'Park maintenance',
    image: require('./7.jpg'),
    description: [
      'After 4-6 months, our specialist will ',
      'maintain the rope park.',
    ]
  }
]
