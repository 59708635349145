function sendOrder(order) {
  window.ym(66921556,'reachGoal','order')
  console.log('Отправка заявки', order)
  return fetch('/order.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(order)
  })
}

export default sendOrder
