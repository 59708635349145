import {
  createSlice,
  createAction,
  createEntityAdapter
} from '@reduxjs/toolkit'

import cuid from 'cuid'

export const status = {
  open: 'open',
  closed: 'closed',
  closing: 'closing'
}

const popupsAdapter = createEntityAdapter()

const openPopupCreate = createAction('popups/openPopup/create')
const openPopupShow = createAction('popups/openPopup/show')
const closePopupHide = createAction('popups/closePopup/hide')
const closePopupRemove = createAction('popups/closePopup/remove')

export function openPopup(type, data) {
  return (dispatch) => {
    const id = cuid()
    dispatch(openPopupCreate({id, type, data, status: status.closed}))
    setTimeout(() => {
      dispatch(openPopupShow(id))
    })
  }
}

export function closePopup(id) {
  return (dispatch) => {
    dispatch(closePopupHide(id))
    setTimeout(() => {
      dispatch(closePopupRemove(id))
    }, 400)
  }
}

const popupsSlice = createSlice({
  name: 'popups',
  initialState: popupsAdapter.getInitialState(),
  extraReducers: {
    [openPopupCreate]: popupsAdapter.addOne,

    [openPopupShow]: (state, action) => {
      popupsAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          status: status.open
        }
      })
    },

    [closePopupHide]: (state, action) => {
      popupsAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          status: status.closing
        }
      })
    },

    [closePopupRemove]: popupsAdapter.removeOne
  }
})

export const {showPopup} = popupsSlice.actions

export const {selectIds, selectById, selectAll} = popupsAdapter.getSelectors()

export function selectContainerStatus(state) {
  return selectAll(state).reduce((current, popup) => {
    if (popup.status === status.closing && current !== status.open) {
      return status.closing
    }
    if (popup.status === status.open) {
      return status.open
    }
    return current
  }, status.closed)
}

export function selectTop(state) {
  const ids = selectIds(state)
  if (ids.length > 0) {
    return ids[ids.length - 1]
  }
  return null
}

export default popupsSlice.reducer
