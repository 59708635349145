import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import mixin from 'utils/mixin'
import Popup from './Popup'
import './PopupsContainer.css'
import {closePopup} from 'popups/popupsSlice'
import {selectIds, selectTop, selectContainerStatus} from 'popups/popupsSlice'

const mix = mixin('PopupsContainer')

const PopupsContainer = () => {
  const status = useSelector(selectContainerStatus)
  const popups = useSelector(selectIds)
  const top = useSelector(selectTop)
  const dispatch = useDispatch()

  function handleClick(event) {
    dispatch(closePopup(top))
  }

  return (
    <div className={mix(status)} onClick={handleClick}>
      {popups.map((id, index) => <Popup key={id} id={id} isLast={index === popups.length - 1}/>)}
      <div className="PopupsContainer-close" onClick={handleClick}/>
    </div>
  )
}

export default PopupsContainer
