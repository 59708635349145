import React from 'react'
import mixin from 'utils/mixin'
import './Icon.css'

const mix = mixin('Icon')

const Icon = ({name, className}) => (
  <i className={mix(name, className)}/>
)

export default Icon
