import React from 'react'
import './Cell.css'
import mixin from 'utils/mixin'

const mix = mixin('Cell')

const Cell = ({size, children, className}) => (
  <div className={mix(size, className)}>
    {children}
  </div>
)

export default Cell
