import React from 'react'
import Screen from 'components/Screen'
import Heading from 'components/Heading'
import steps from 'data/steps'
import './Steps.css'

const Steps = () => (
  <Screen type="collapsed" className="Steps">
    <Heading type={['big', 'full']} level="2">
      <span className="small">
      Step by step
      </span>
      How we are working
    </Heading>
    <div className="Steps-items">
      {steps.map((item, index) => (
        <article key={index} className="Steps-item">
          <h3 className="Steps-title">
            {item.title}
          </h3>
          <img src={item.image} alt="" className="Steps-img"/>
          <div className="Steps-number">
            {'0' + (index + 1)}
          </div>
          <p className="Steps-description">
            {Array.isArray(item.description) ? item.description.map((line, index) => (
              <span key={index} className="Steps-line">
                {line}
              </span>
            )) : item.description}
          </p>
        </article>
      ))}
    </div>
  </Screen>
)

export default Steps
