import React from 'react'
import {useSelector} from 'react-redux'
import {selectById} from 'popups/popupsSlice'
import Notification from './Notification'
import PopupForm from './PopupForm'
import ParkPopup from './ParkPopup'
import mixin from 'utils/mixin'
import './Popup.css'

const mix = mixin('Popup-window')

const Popup = ({id, isLast}) => {
  const popup = useSelector(state => selectById(state, id))
  return (
    <div className="Popup" onClick={e => e.stopPropagation()} style={{
      visibility: isLast ? 'visible' : 'hidden'
    }}>
      {getPopupBody(popup)}
    </div>
  )
}

function getPopupBody(popup) {
  switch (popup.type) {
    case 'form':
      return <PopupForm
        id={popup.id}
        source={popup.data}
        className={mix(popup.status)}
      />
    case 'notification':
      return <Notification
        id={popup.id}
        message={popup.data}
        className={mix(popup.status)}
      />
    case 'park':
      return <ParkPopup
        park={popup.data}
        className={mix(popup.status)}
      />
    default:
      return 'Неизвестный тип попапа'
  }
}

export default Popup
