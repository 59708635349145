export default [
  {
    city: 'Uzbekistan',
    area: 1200,
    capacity: 160,
    flow: 90,
    background: require('./9.jpg'),
    images: [
      require('./9-1.jpg'),
      require('./9-2.jpg'),
      require('./9-3.jpg')
    ]
  },
  {
    city: 'Yoshkar-Ola',
    area: 300,
    capacity: 88,
    flow: 80,
    background: require('./1.jpg'),
    images: [
      require('./1-1.jpg'),
      require('./1-2.jpg'),
      require('./1-3.jpg')
    ]
  },
  
  {
    city: 'Cherepovets',
    area: 700,
    capacity: 115,
    flow: 100,
    background: require('./2.jpg'),
    images: [
      require('./2-1.jpg'),
      require('./2-2.jpg'),
      require('./2-3.jpg')
    ]
  },
  {
    city: 'Noginsk',
    area: 800,
    capacity: 130,
    flow: 120,
    background: require('./3.jpg'),
    images: [
      require('./3-1.jpg'),
      require('./3-2.jpg'),
      require('./3-3.jpg')
    ]
  },
  {
    city: 'Voronezh',
    area: 800,
    capacity: 130,
    flow: 110,
    background: require('./4.jpg'),
    images: [
      require('./4-1.jpg'),
      require('./4-2.jpg'),
      require('./4-3.jpg')
    ]
  },
  {
    city: 'Krinitsa',
    area: 800,
    capacity: 130,
    flow: 80,
    background: require('./5.jpg'),
    images: [
      require('./5-1.jpg'),
      require('./5-2.jpg'),
      require('./5-3.jpg')
    ]
  },
  {
    city: 'Tashkent',
    area: 350,
    capacity: 48,
    flow: 50,
    background: require('./6.jpg'),
    images: [
      require('./6-1.jpg'),
      require('./6-2.jpg'),
      require('./6-3.jpg')
    ]
  },
  {
    city: 'Arkhipo-osipovka',
    area: 800,
    capacity: 130,
    flow: 60,
    background: require('./7.jpg'),
    images: [
      require('./7-1.jpg'),
      require('./7-2.jpg'),
      require('./7-3.jpg')
    ]
  },
  {
    city: 'Smolensk',
    area: 800,
    capacity: 130,
    flow: 70,
    background: require('./8.jpg'),
    images: [
      require('./8-1.jpg'),
      require('./8-2.jpg'),
      require('./8-3.jpg')
    ]
  }
]
