import React from 'react'
import './Heading.css'
import mixin from 'utils/mixin'

const mix = mixin('Heading')

const Heading = ({level = '2', type, children, className}) => {
  switch (level) {
    case '1':
      return (
        <h1 className={mix(type, className)}>
          {children}
        </h1>
      )
    case '3':
      return (
        <h3 className={mix(type, className)}>
          {children}
        </h3>
      )
    case '4':
      return (
        <h4 className={mix(type, className)}>
          {children}
        </h4>
      )
    default:
      return (
        <h2 className={mix(type, className)}>
          {children}
        </h2>
      )
  }
}

export default Heading
