export default [
  {
    author: 'Alex Bankovich',
    position: 'Chief installer',
    picture: require('./10.png'),
    text: 'We have completed the installation of a modular rope park on supports. All stages have been tested, the park has been put into operation.'
  },
  {
    author: 'Sergei Chernousov',
    position: 'Chief installer',
    picture: require('./11.png'),
    text: 'We have completed the installation of a rope park on trees. The park includes 2 routes, 4 zip lines, one of which passes through the lake 150m. The park turned out to be very interesting. All stages have been tested, the park has been put into operation.'
  },
  {
    author: 'Max Bogatyrev',
    position: 'Logistics Department',
    picture: require('./1.png'),
    text: 'fter the completion of the installation, a wonderful customer organized a very pleasant evening as a token of gratitude.'
  },
  {
    author: 'Andrey Smolov',
    position: 'Chief installer',
    picture: require('./5.png'),
    text: 'We installed the original rope park on the trees of the 3rd route. The customer is delighted.'
  },
  {
    author: 'Alan Sborovsky',
    position: 'Project Manager',
    picture: require('./6.png'),
    text: 'We installed two rope parks at the recreation center. Tested the new Kong tandem rollers for zip lines. The customer is satisfied with the quality of materials and installation work.'
  },
  {
    author: 'Viktor Andruevsky',
    position: 'Director of operations',
    picture: require('./7.png'),
    text: 'Successfully completed the installation of 4 routes in a rented premises on existing metal columns. The columns were not drilled. The rope park organically fit into the room, taking up the maximum possible space.'
  }
]
