import React from 'react'
import Screen from 'components/Screen'
import Heading from 'components/Heading'
import testimonials from 'data/testimonials'
import './Testimonials.css'

const Testimonials = () => (
  <Screen type="collapsed" className="Testimonials">
    <Heading type={['big', 'full']} level="2">
      <span className="small">
      reviews our customers
      </span>
      about us
    </Heading>
    <div className="Testimonials-items">
      {testimonials.map((item, index) => (
        <article key={index} className="Testimonials-item">
          <h3 className="Testimonials-authors">
            {item.authors}
          </h3>
          <img
            src={item.photo}
            alt="Аватарка"
            className={
              item.square
                ? 'Testimonials-img Testimonials-img--square'
                : 'Testimonials-img'
            }
          />
          <p className="Testimonials-text">
            {item.text}
          </p>
        </article>
      ))}
    </div>
  </Screen>
)

export default Testimonials
