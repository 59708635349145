import React, {useRef, useState, useEffect} from 'react'
import './Screen.css'
import mixin from 'utils/mixin'
import useLazyBackground from 'utils/lazyBackground'

const mix = mixin('Screen')

const Screen = ({type, children, parallax, background, className}) => {
  const container = useRef(null)
  const offset = useParallax(container, parallax)
  const src = useLazyBackground(container, background)

  return (
    <section
      ref={container}
      className={mix(type, className)}
      style={{
        backgroundPosition: `center ${offset}px`,
        backgroundImage: src ? `url(${src})` : null
      }}
    >
      <div className="Screen-inner">
        {children}
      </div>
    </section>
  )
}

function useParallax(container, parallax) {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    function handleScroll(event) {
      const {offsetTop, clientHeight} = container.current
      const {scrollY, innerHeight} = window
      if (
        scrollY < offsetTop + clientHeight &&
        scrollY + innerHeight > offsetTop
      ) {
        window.requestAnimationFrame(() => {
          setOffset((scrollY - offsetTop) / 3)
        })
      }
    }

    if (parallax) {
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [container, parallax])

  return offset
}

export default Screen
