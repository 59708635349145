import {useState} from 'react'
import produce from 'immer'

function getTouchPos(event) {
  const {pageX, pageY} = event.touches
    ? event.touches[0]
    : event
  return {pageX, pageY}
}

function useSwipe(onEnd) {
  const [isMoving, setMoving] = useState(false)
  const [pos, setPos] = useState({
    start: 0, current: 0
  })

  function handleStart(event) {
    const {pageX} = getTouchPos(event)
    setPos({
      start: pageX,
      current: pageX
    })
    setMoving(true)
  }

  function handleMove(event) {
    const {pageX} = getTouchPos(event)
    setPos(produce(pos => {
      pos.current = pageX
    }))
  }

  function handleEnd(event) {
    if (Math.abs(pos.current - pos.start) > 30) {
      const action = Math.sign(pos.current - pos.start) > 0
        ? 'left'
        : 'right'
      onEnd(action)
    }
    setMoving(false)
  }

  return {
    offset: isMoving ? pos.current - pos.start : 0,
    handleStart, handleMove, handleEnd
  }
}

export default useSwipe
