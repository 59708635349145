import React from 'react'
import './Button.css'
import mixin from 'utils/mixin'

const mix = mixin('Button')

const Button = ({type, payload, children, className, onClick}) => {
  function handleClick(event) {
    event.preventDefault()
    if (typeof onClick !== 'undefined') {
      onClick(payload)
    }
  }

  return (
    <button className={mix(type, className)} onClick={handleClick}>
      {children}
    </button>
  )
}

export default Button
