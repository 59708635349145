import {useRef, useState, useEffect} from 'react'

function useLazyBackground(container, targetSrc) {
  const [src, setSrc] = useState(null)
  const timer = useRef(null)

  useEffect(() => {
    function handleScroll(event) {
      const {offsetTop} = container.current
      const {scrollY} = window
      if (scrollY > offsetTop - 2000) {
        timer.current = setTimeout(() => {
          window.removeEventListener('scroll', handleScroll)
          setSrc(targetSrc)
        }, 50)
      }
    }

    if (targetSrc) {
      handleScroll()
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
        clearTimeout(timer.current)
      }
    }
  }, [container, targetSrc])

  return src
}

export default useLazyBackground
