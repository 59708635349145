import React, {useState, useRef} from 'react'
import useSwipe from 'utils/swipe'
import objects from 'data/objects'
import './Objects.css'
import Screen from 'components/Screen'
import Heading from 'components/Heading'

const Objects = () => {
  const [offset, setOffset] = useState(0)
  const carousel = useRef(null)

  const touch = useSwipe(action => {
    if (action === 'left') {
      handleLeft()
    } else {
      handleRight()
    }
  })

  function handleLeft(event) {
    setOffset(offset => offset > 0 ? offset - 1 : 0)
  }

  function handleRight(event) {
    const latest = objects.length - 1
    setOffset(offset => offset < latest ? offset + 1 : latest)
  }

  function handleClick(event) {
    carousel.current.focus()
  }

  function handleKeyDown(event) {
    if (event.key === 'ArrowLeft') {
      handleLeft(event)
    } else if (event.key === 'ArrowRight') {
      handleRight(event)
    }
  }

  return (
    <Screen type="collapsed" className="Objects">
      <Heading type={['big', 'full']} level="2">
        <span className="small">
        reviews
        </span>
        about our park
      </Heading>
      <div ref={carousel} className="Objects-carousel"
        tabIndex="-1"
        onClick={handleClick}
        onMouseEnter={handleClick}
        onKeyDown={handleKeyDown}
        onTouchStart={touch.handleStart}
        onTouchMove={touch.handleMove}
        onTouchEnd={touch.handleEnd}
        onMouseDown={touch.handleStart}
        onMouseMove={touch.handleMove}
        onMouseUp={touch.handleEnd}
      >
        <div
          className="Objects-content"
          style={{
            width: `${100 * objects.length}%`,
            transform: `translateX(-${offset * 100 / objects.length}%) translateX(${touch.offset}px)`,
            transition: touch.offset === 0
              ? 'transform ease-out 0.4s'
              : 'transform ease-out 0.2s'
          }}
        >
          {objects.map((item, index) => (
            <article
              key={index}
              className="Objects-item"
              style={{width: `${100 / objects.length}%`}}
            >
              <header className="Objects-header">
                <h3 className="Objects-author">
                  {item.author}
                </h3>
                <p className="Objects-position">
                  {item.position}
                </p>
              </header>
              <div className="Objects-picture">
                <img src={item.picture} alt="" className="Objects-img"/>
              </div>
              <p className="Objects-text">
                {item.text}
              </p>
            </article>
          ))}
        </div>
        <div className="Objects-dots">
          {objects.map((item, index) => (
            <div
              key={index}
              className={
                index === offset
                  ? 'Objects-dot Objects-dot--active'
                  : 'Objects-dot'
              }
              onClick={() => setOffset(index)}
            />
          ))}
        </div>
        <div className="Objects-left" onClick={handleLeft}/>
        <div className="Objects-right" onClick={handleRight}/>
      </div>
    </Screen>
  )
}

export default Objects
